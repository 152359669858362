<template>
  <a-card>
    <a-page-header title="平台账套管理" style="margin-top: -20px; margin-bottom: -12px" />

    <a-form-model :model="form" v-bind="layoutTop">
      <a-row type="flex" justify="start" style="margin-bottom: -20px">
        <a-col :span="7">
          <a-form-model-item label="查询周期">
            <a-range-picker
              v-model="pickerTime"
              value-format="YYYY-MM-DD"
              :allow-clear="false"
              @calendarChange="calendarChange"
              @openChange="openChange"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="广告主">
            <a-input v-model="form.adAdvertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="平台账套ID">
            <a-input v-model="form.adPlatformAccountId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="投手">
            <a-select
              v-model="form.adOperatorId"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 100%"
            >
              <a-select-option v-for="item in pitcherList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="账套名称">
            <a-input v-model.trim="form.adPlatformAccountName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="账套标签">
            <a-select v-model="form.tagIdList" mode="multiple" placeholder="请选择" allow-clear>
              <a-select-option v-for="item in accountHjkTagList" :key="item.id" :value="item.id">
                {{ item.tagName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="flex-end">
          <a-space>
            <a-button @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">查询</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      class="Atables"
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :rowKey="(record) => record.id"
      :pagination="false"
      :scroll="{ x: 2000 }"
      bordered
      :expanded-row-keys.sync="expandedRowKeys"
      @change="handleTableChange"
    >
      <div slot="nameID" slot-scope="text, record" :class="[`z-hierarchy-${record.__hierarchy__}`]" class="show-name">
        <div v-if="record.__hierarchy__ == 2">
          <a @click.stop="handleJump('advertisingProjectManage', record.id)" style="display: block; font-weight: 500">{{
            record.name || '-'
          }}</a>
          <a @click.stop="handleJump('advertisingProjectManage', record.id)">{{ record.id || '-' }}</a>
        </div>
        <div v-if="record.__hierarchy__ == 3">
          <a @click.stop="handleJump('advertisingManage', record.id)" style="display: block; font-weight: 500">
            {{ record.name || '-' }}</a
          >
          <a @click.stop="handleJump('advertisingManage', record.id)">{{ record.id || '-' }}</a>
        </div>
        <div v-if="record.__hierarchy__ == 1">
          <p style="display: block; font-weight: 500">{{ record.name || '-' }}</p>
          <p>{{ record.id || '-' }}</p>
        </div>
        <AButton v-if="record.children" type="link" @click.stop="onExpandedChange(record)">
          {{ expandedRowKeys.includes(record.id) ? '收起' : '展开' }}
        </AButton>
      </div>
      <p
        slot="expandedRowRender"
        slot-scope="record"
        style="margin: 0; font-size: 14px; color: #00a0e9; padding-left: 15%"
        v-if="record.isNoData"
      >
        暂无数据
      </p>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import moment from 'moment/moment';
import { isEmpty, isEqual, pick } from 'lodash-es';
import accountHjkTagApi from '@/api/accountHjkTag';

export default {
  data() {
    return {
      layoutTop: {
        labelCol: { span: 4 },
        wrapperCol: { span: 15 },
      },
      layoutBottom: {
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
      },
      form: {
        adAdvertiserName: '',
        adPlatformAccountId: '',
        adOperatorId: undefined,
        adPlatformAccountName: '',
        tagIdList: [],
      },
      accountHjkTagList: [],
      pickerTime: [moment(), moment()],
      selectStartDay: '',
      pitcherList: [],
      loading: false,
      dataList: [],
      columns: [
        {
          align: 'center',
          title: '名称/ID',
          width: 200,
          dataIndex: 'name',
          key: 'id',
          scopedSlots: { customRender: 'nameID' },
        },
        {
          align: 'center',
          title: '账套名称',
          key: 'adPlatformAccountName',
          dataIndex: 'adPlatformAccountName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '账套标签',
          key: 'tagList',
          dataIndex: 'tagList',
          customRender(text) {
            return text.map((item) => item.tagName).join(',') || '-';
          },
        },
        {
          align: 'center',
          title: '状态',
          dataIndex: 'status',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '预算（元）',
          dataIndex: 'budget',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '出价（元）',
          dataIndex: 'bid',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '消费（元）',
          dataIndex: 'statCost',
          key: 'statCost',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '目标转化数',
          dataIndex: 'convertCnt',
          key: 'convertCnt',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '转化单价（元）',
          dataIndex: 'convertCost',
          key: 'convertCost',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '展示数',
          dataIndex: 'showCnt',
          key: 'showCnt',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '点击数',
          dataIndex: 'clickCnt',
          key: 'clickCnt',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '私信数',
          dataIndex: 'messageAction',
          key: 'messageAction',
          width: 90,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '私信留资数',
          dataIndex: 'clueMessageCount',
          key: 'clueMessageCount',
          width: 100,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '私信留资单价（元）',
          dataIndex: 'unitPriceMessageAction',
          key: 'unitPriceMessageAction',
          width: 100,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '表单提交数',
          key: 'form',
          dataIndex: 'form',
          width: 100,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '千次展示单价(元)',
          dataIndex: 'unitPricePerThousandShow',
          key: 'unitPricePerThousandShow',
          width: 100,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '展示转化率（%）',
          dataIndex: 'showConvertRate',
          key: 'showConvertRate',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '点击率（%）',
          dataIndex: 'clickRate',
          key: 'clickRate',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '点击转化率（%）',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'clickConvertRate',
          key: 'clickConvertRate',
          width: 80,
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '点击单价(元)',
          dataIndex: 'clickUnitPrice',
          key: 'clickUnitPrice',
          width: 80,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          title: '私信留资率（%）',
          dataIndex: 'clueMessageCountRate',
          key: 'clueMessageCountRate',
          width: 80,
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '投手',
          dataIndex: 'operatorName',
          width: 80,
          customRender(text) {
            return text || '-';
          },
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
      expandedRowKeys: [],
      sortList: [],
    };
  },
  mounted() {
    if (this.$route.query.id || this.$route.query.DATA_TIME) {
      this.form.adPlatformAccountId = this.$route.query.id;
      this.pickerTime = this.$route.query.DATA_TIME;
    } else {
      try {
        const params = JSON.parse(sessionStorage.getItem('__platform_credit_manage__') || '{}');
        if (!isEmpty(params)) {
          this.form = { ...this.form, ...pick(params, ['adAdvertiserName', 'adPlatformAccountId', 'adOperatorId']) };
          if (params.startTime) this.pickerTime = [moment(params.startTime), moment(params.endTime)];
          // this.sortList = params.sortList;
          this.pagination.current = params.page;
          this.pagination.pageSize = params.size;
        }
      } finally {
      }
    }

    this.$nextTick(this.init());
  },
  methods: {
    init() {
      this.getHjkAccountqAccountList();
      this.getUsersList();
      this.getAccountHjkTagList();
    },
    getAccountHjkTagList() {
      accountHjkTagApi
        .getHjkTagListApi({ page: 1, size: 9999 })
        .then((res) => {
          if (res.code == 200) {
            this.accountHjkTagList = res.data.list || [];
          } else {
            this.accountHjkTagList = [];
            console.error(`获取列表失败${res}`);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getHjkAccountqAccountList() {
      this.loading = true;
      const _form = this.form;

      let params = {
        ..._form,
        startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
        endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        sortList: this.sortList,
      };
      sessionStorage.setItem('__platform_credit_manage__', JSON.stringify(params));

      this.$api.core.JLAdvertManage.getHjkAccountqAccountList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = (res.data.list || []).map((item) => ({ ...item, __hierarchy__: 1, children: [] }));
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${res.message}`);
        }
      });
    }, // 一级广告主账套列表
    getHjkAccountProjectList(id) {
      this.loading = true;
      const _form = this.form;

      let params = {
        ..._form,
        startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
        endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        id,
      };
      this.$api.core.JLAdvertManage.getHjkAccountProjectList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          if (!res.data.length) {
            this.$message.warning('暂无数据');
          }
          this.dataList = (this.dataList || []).flatMap((item) => {
            if (isEqual(item.id, id)) {
              return [
                {
                  ...item,
                  isNoData: isEmpty(res.data),
                  children: (res.data || []).map((child) => ({
                    ...child,
                    __hierarchy__: 2,
                    children: [],
                  })),
                },
              ];
            } else {
              return [item];
            }
          });
          console.log(this.dataList);
        } else {
          console.error(`获取列表失败${res.message}`);
        }
      });
    }, // 二级项目列表
    getHjkAccountPromotionList(id, parentId) {
      this.loading = true;
      const _form = this.form;

      let params = {
        ..._form,
        startTime: `${moment(this.pickerTime[0]).format('YYYY-MM-DD')} 00:00:00`,
        endTime: `${moment(this.pickerTime[1]).format('YYYY-MM-DD')} 23:59:59`,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        id: id, // 二级项目ID
      };
      this.$api.core.JLAdvertManage.getHjkAccountPromotionList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          if (!res.data.length) {
            this.$message.warning('暂无数据');
          }
          this.dataList = (this.dataList || []).map((item) => {
            // pipeiyiji
            if (isEqual(item.id, parentId)) {
              item.children = item.children.flatMap((child) => {
                // PIPEIERJIID
                if (isEqual(child.id, id)) {
                  return [
                    {
                      ...child,
                      isNoData: isEmpty(res.data),
                      children: (res.data || []).map((s) => ({
                        ...s,
                        __hierarchy__: 3,
                      })),
                    },
                  ];
                } else {
                  return [child];
                }
              });
            }
            return item;
          });
          console.log(res);
        } else {
          console.error(`获取列表失败${res.message}`);
        }
      });
    }, // 三级项目列表
    getUsersList() {
      this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
        if (res.code == 200) {
          this.pitcherList = res.data.list;
        } else {
          this.$message.error(`获取投手列表失败，${res.message}`);
        }
      });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.getHjkAccountqAccountList();
    },
    handleReset() {
      this.form = {
        adAdvertiserName: '',
        adPlatformAccountId: '',
        adOperatorId: undefined,
      };
      this.pickerTime = [moment(), moment()];
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectStartDay = '';
      this.expandedRowKeys = [];
      this.getHjkAccountqAccountList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getHjkAccountqAccountList();
    },
    calendarChange(dates) {
      this.selectStartDay = dates[0];
    },
    openChange() {
      this.selectStartDay = '';
    },

    // 处理展开合并
    onExpandedChange(row) {
      const index = this.expandedRowKeys.indexOf(row.id);
      if (index !== -1) {
        this.expandedRowKeys.splice(index, 1);
      } else {
        this.expandedRowKeys.push(row.id);

        if (row.__hierarchy__ === 1) {
          this.getHjkAccountProjectList(row.id);
        }

        if (row.__hierarchy__ === 2) {
          this.getHjkAccountPromotionList(row.id, row.parentId);
        }
      }
    },

    handleJump(name, id) {
      if (name == 'advertisingManage') {
        this.$router.push({ name: name, params: { adPromotionId: id } });
      } else {
        this.$router.push({ name: name, params: { id: id } });
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const sort = sorter.order
        ? [
            {
              fieldName: sorter.columnKey,
              orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            },
          ]
        : [];
      this.sortList = sort;
      this.pagination.current = 1;
      this.getHjkAccountqAccountList();
    },
  },
};
</script>

<style scoped lang="less">
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.Atables /deep/ .ant-table {
  font-size: 10px;
}

.Atables /deep/ .ant-table th,
.Atables /deep/ .ant-table td {
  font-size: 10px;
}

.show-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .ant-table-row {
  .ant-table-row-expand-icon {
    display: none;
  }

  &:has(.ant-table-row-cell-break-word .z-hierarchy-1) {
    background: #e5efff;
  }

  &:has(.ant-table-row-cell-break-word .z-hierarchy-2) {
    background: #eafaf1;
  }

  &:has(.ant-table-row-cell-break-word .z-hierarchy-3) {
    background: #ffe9e8;
  }
}

/deep/ .ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  padding: 0 !important;
  width: 0 !important;
  border-right: none !important;
}

/deep/ .ant-table-expanded-row {
  display: none !important;
}
</style>
